.Player {
  column-gap: 16px;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: calc(100% * 6 / 72) calc(100% * 66 / 72);
  height: 100vh;
}

.Bottle,
.NextCapsule {
  height: 100%;
}

.Bottle {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
}

.NextCapsule {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  justify-self: center;
}

.Info {
  align-self: center;
  grid-column: 2 / 2;
  grid-row: 1 / span 2;
}

canvas {
  image-rendering: pixelated;
}
