@use '../util/mixins';

.El {
  @include mixins.screen;
}

.Title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
}

.Options {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.Option {
  display: contents;
}

.Label {
  justify-self: end;
  padding-right: 16px;
}

.Value {
  justify-self: start;
}

.Label,
.Value {
  border-bottom: 4px solid transparent;
  padding-bottom: 8px;
  margin-top: 8px;
}

.ValueButton {
  background-color: transparent;
  border: 0;
  font-family: monospace;
  font-size: 32px;
  padding: 0 0 8px;
}

.Label,
.Value,
.ValueButton {
  color: #999;
}

.Option:first-child {
  .Label,
  .Value {
    margin-top: 0;
  }
}

.Option:hover,
.Option.isSelected {
  .Label,
  .Value,
  .ValueButton {
    border-bottom-color: #fff;
    color: #fff;
  }
}

.UpdatingDialogContainer {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.UpdatingDialog {
  background-color: #000;
  border: 4px solid #fff;
  color: #fff;
  padding: 32px;
  text-align: center;
}

.Input {
  background-color: #000;
  border: 0;
  border-bottom: 4px solid #fff;
  color: #fff;
  font-family: monospace;
  font-size: 32px;
}

.DialogButton {
  background-color: transparent;
  border: 0;
  border-bottom: 4px solid transparent;
  color: #ccc;
  cursor: pointer;
  font-family: monospace;
  font-size: 32px;
  margin-bottom: -8px;
  margin-right: 32px;
  margin-top: 16px;
  padding: 8px 0;

  &:last-child {
    margin-right: 0;
  }
}

.DialogButton:hover,
.DialogButton:focus,
.Button:hover,
.Button.isSelected {
  border-bottom-color: #fff;
  color: #fff;
}

.Button {
  background-color: transparent;
  border: 0;
  border-bottom: 4px solid transparent;
  color: #ccc;
  font-family: monospace;
  font-size: 32px;
  padding: 8px 0;
}
