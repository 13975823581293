@use '../util/mixins';

.El {
  @include mixins.screen;
}

.Title {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 16px;
}

.Options {
  display: flex;
  gap: 32px;
}

.Option {
  background-color: transparent;
  border: 0;
  border-bottom: 4px solid transparent;
  color: #999;
  cursor: pointer;
  font-family: monospace;
  font-size: 32px;
  padding: 0 0 8px;
}

.Option:hover,
.Option.isSelected {
  border-bottom-color: #fff;
  color: #fff;
}
