@use '../util/mixins';

.El {
  @include mixins.screen;
}

.Title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
}

.Lobby {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr max-content;
}

.Peers {
  border: 4px solid #fff;
  padding: 16px;
}

.Peer {
  align-items: center;
  border-bottom: 4px solid transparent;
  color: #999;
  display: grid;
  gap: 8px;
  grid-template-columns: min-content 1fr;
  padding: 4px 8px;
}

.Peer.isUiElementSelected {
  border-bottom-color: #fff;
}

.Peer.isSelected {
  background-color: #333;
  color: #fff;
}

.StatusIndicator {
  background-color: white;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
}

.StatusInLobby {
  background-color: yellow;
}

.StatusAvailable {
  background-color: green;
}

.StatusConnected {
  background-color: red;
}

.Actions {
  display: flex;
  flex-direction: column;
}

.Action {
  border-bottom: 4px solid transparent;
  color: #999;
  cursor: pointer;
  display: inline-block;
  margin-top: 8px;
  padding-bottom: 8px;
}

.Action:hover,
.Action.isSelected {
  border-bottom-color: #fff;
  color: #fff;
}
