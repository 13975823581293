.Game {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: monospace;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.Players {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
