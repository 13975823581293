@use '../util/mixins';

.El {
  @include mixins.screen;
}

.Title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
}

.Options {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.Option {
  color: #999;
  display: contents;
}

.Option.isSelected {
  color: #fff;
}

.Label {
  justify-self: end;
  padding-right: 16px;
}

.Value {
  justify-self: start;
}

.Label,
.Value {
  border-bottom: 4px solid transparent;
  padding-bottom: 8px;
  margin-top: 8px;
}

.Option:first-child {
  .Label,
  .Value {
    margin-top: 0;
  }
}

.Option.isSelected {
  .Label,
  .Value {
    border-bottom-color: #fff;
  }
}

.Seed {
  position: relative;
}

.SeedDigit {
  border: 0.5ch solid transparent;
  border-bottom: 0;
  border-top: 1ch solid #fff;
  display: none;
  height: 0;
  position: absolute;
  top: -1ch;
  width: 0;
}

.Option.isSelected .SeedDigit {
  display: block;
}
